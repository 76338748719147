<template>
  <div class="m_page_container_all">
    <div id="p_aloe_header">
      <div id="p_aloe_header_title">{{ info.header.title }}</div>
      <div id="p_aloe_header_des">{{ info.header.des }}</div>
      <div id="p_aloe_header_list">
        <div
          id="p_aloe_header_item"
          v-for="(item, index) in info.header.items"
          :key="index"
        >
          <box-with-style-1 :item="item" />
        </div>
      </div>
    </div>
    <div id="p_aloe_produce_header">
      <div id="p_aloe_produce_header_title">{{ info.produce.title }}</div>
      <div id="p_aloe_produce_header_des_content">
        <img id="p_aloe_produce_header_icon" src="@/assets/doubleQuotes.png" />
        <div
          v-for="(des, index) in info.produce.desList"
          :key="index"
          id="p_aloe_produce_header_text"
        >
          {{ des }}
        </div>
      </div>
    </div>
    <div id="p_aloe_produce_slider">
      <div id="p_aloe_produce_title">
        <div
          v-on:click="handleSwitchTab(0)"
          :id="
            selectedIndex == 0
              ? 'p_aloe_produce_title_selected'
              : 'p_aloe_produce_title_normal'
          "
        >
          芦荟护肤
        </div>
        <hr id="p_aloe_produce_title_hr" />
        <div
          v-on:click="handleSwitchTab(1)"
          :id="
            selectedIndex == 1
              ? 'p_aloe_produce_title_selected'
              : 'p_aloe_produce_title_normal'
          "
        >
          芦荟洗护
        </div>
      </div>
      <div id="p_aloe_produce_list">
        <div
          id="p_aloe_produce_item"
          v-for="(item, index) in list"
          :key="index"
        >
          <box-with-style-2 :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import BoxWithStyle1 from "@/components/Mobile/BoxWithStyle1.vue";
import BoxWithStyle2 from "@/components/Mobile/BoxWithStyle2.vue";
import { kAloePageContentInfo } from "@/config/aloe";

@Options({
  components: {
    BoxWithStyle1,
    BoxWithStyle2,
  },
  data() {
    return {
      info: kAloePageContentInfo,
      selectedIndex: 0,
      list: kAloePageContentInfo.skinCare.items,
      produce_items: [
        {
          name: "芦荟护肤",
        },
        {
          name: "芦荟洗护",
        },
      ],
    };
  },
  methods: {
    handleSwitchTab(index) {
      window.document.title = this.produce_items[index].name;
      this.selectedIndex = index;
      this.list =
        index === 0
          ? kAloePageContentInfo.skinCare.items
          : kAloePageContentInfo.wash.items;
    },
  },
})
export default class AloePage extends Vue {}
</script>

<style scoped>
@import url("../../../less/mobile/page.less");
@import url("../../../less/mobile/Aloe/index.less");
</style>